import { CompanySearchConfig } from '@common/constants';
import { showErrorToast } from '@components/Toast';
import request, { requestCoreApi, requestUrl } from './request';
import searchCompaniesQuery from './queries/searchCompanies';
import searchCompaniesQueryV2Deprecated from './queries/searchCompaniesV2Deprecated';
import availableFilterTerms from './queries/availableCompanySearchFilterTerms';
import { getOmniEmbedMetadataQuery } from './queries/getOmniEmbedMetadataQuery';

// Companies
// ============================================================================
export async function searchCompanies({ query, limit, offset }) {
  return request.get(
    `/companies/?search=${encodeURIComponent(
      query
    )}&limit=${limit}&offset=${offset}`
  );
}

export async function bulkDeleteParentCompanies({ payload, projectId }) {
  return request.post(
    `/benchmarkorganizations/bulk_delete/?project_id=${projectId}`,
    payload
  );
}

export async function addChildCompany({ parentCompanyId, companyId }) {
  return (
    await request.post(`/benchmarkorganizations/${parentCompanyId}/companies/`),
    {
      company: companyId,
    }
  );
}

export async function bulkCreateParentCompanies({ payload, projectId }) {
  return request.post(
    `/benchmarkorganizations/bulk_create/?project_id=${projectId}`,
    payload
  );
}

// ETL jobs
// ============================================================================
export async function triggerEltJob({ projectId }) {
  return request.post(
    `/benchmarkorganizations/etl_job_request/?project_id=${projectId}`,
    {}
  );
}

// Dashboards
// ============================================================================
export async function getDashboards(projectId) {
  return await request.get(`/v2/dashboards/?project=${projectId}`);
}

// Roles
// ============================================================================
export async function getRoles(projectId) {
  return await request.get(`/v2/rolehierarchy/?projectid=${projectId}`);
}

export async function updateRoles() {
  return Promise.resolve(true);
}

export async function getS3SignedUrl(
  bucketName = 'rolemapping_uploads',
  fileName
) {
  return await request.post(
    `/v2/table/generate-s3-signed-url/?bucket_name=${bucketName}&prefix=${fileName}&action=UPLOAD`
  );
}

// Projects
// ============================================================================
export async function getTemplates() {
  return await request.get(`/blueprints/industry_templates/`);
}

export async function getProjects() {
  return await request.get(`/blueprints/`);
}

export async function postProjects({ newProject, isBainUser, isPaygUser }) {
  if (isBainUser || isPaygUser) {
    return await request.post(`/blueprints/`, newProject);
  } else {
    return await request.post(`/blueprints/`, {
      name: newProject,
    });
  }
}

export async function deleteProject(projectID) {
  return await request.delete(`/blueprints/${projectID}/`);
}

export async function renameProject({ projectID, newProjectName, isBainUser }) {
  if (isBainUser) {
    return await request.put(`/blueprints/${projectID}/`, newProjectName);
  } else {
    return await request.patch(`/blueprints/${projectID}/`, {
      name: newProjectName,
    });
  }
}

export async function duplicateProject(projectID, payload) {
  return await request.post(`/blueprints/${projectID}/clone/`, payload);
}

export async function getProjectStatus(projectID) {
  return await request.get(`/blueprints/${projectID}/project_status/`);
}

export async function triggerExport(projectID) {
  return await request.get(
    `/v2/table/trigger_export_zip/?projectid=${projectID}`
  );
}

// Tables
// ============================================================================
export async function getTableFields({ projectId, model }) {
  return await request.get(
    `/v2/metadata/?projectid=${projectId}&model=${model}`
  );
}

export async function getTableFieldValues({ projectId, model, field }) {
  return await request.get(
    `/v2/metadata/${field}/?projectid=${projectId}&model=${model}`
  );
}
export async function getDates() {
  return await request.get(`/v2/metadata/refresh_dates`);
}

export function serialize(obj) {
  var str = [];
  for (var p in obj)
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      if (obj[p] === undefined) {
        continue;
      }
      if (typeof obj[p] === 'object') {
        obj[p] = JSON.stringify(obj[p]);
      }
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
}

export async function getTableData({
  projectId,
  model,
  filters,
  page,
  pageSize,
  sortField,
  sortAsc = false,
  schema,
}) {
  const params = serialize({
    projectid: projectId,
    schema,
    model,
    filters,
    page,
    page_size: pageSize,
    orderby: sortField ? `${sortField} ${sortAsc ? 'asc' : 'desc'}` : undefined,
  });

  return await request.get(`/v2/table/?${params}`);
}

export async function getSQTableData({
  projectId,
  model,
  filters,
  page,
  pageSize,
  sortField,
  sortAsc = false,
  schema,
  alumni_companies,
}) {
  const parsedFilters = filters
    ? filters.filter(
        f =>
          !(
            f.field === 'main_company_name' &&
            (!f.query || f.query.length === 0)
          ) && !f.query.includes('NORESULTS')
      )
    : [];

  const params = {
    projectid: projectId,
    schema,
    page,
    page_size: pageSize,
    orderby: sortField ? `${sortField} ${sortAsc ? 'asc' : 'desc'}` : undefined,
  };

  if (alumni_companies?.length > 0) {
    params.alumni_companies = alumni_companies;
  }

  if (model === CompanySearchConfig.METADATA_MODEL) {
    params.filters = parsedFilters;
    const serializedParams = serialize(params);

    return await request.get(`/v2/sourcing/metadata/?${serializedParams}`);
  }

  const serializedParams = serialize(params);
  const { data, total } = await request.post(
    `/v2/sourcing/get_acs_data/?${serializedParams}`,
    parsedFilters
  );

  return [
    {
      data,
      pagination: { total, page: 1 },
      totalResults: total,
    },
  ];
}

export async function getAcsCsvData(
  filters = [],
  customColumnReordering = [],
  sortField,
  sortAsc = false,
  page_size = 10
) {
  const queryParams = serialize({
    orderby: sortField ? `${sortField} ${sortAsc ? 'asc' : 'desc'}` : undefined,
    ...(page_size ? { page_size } : {}),
  });

  const tableColumns = customColumnReordering.filter(
    col => col.db_name !== 'db_count'
  );

  return await request.post(
    `/v2/sourcing/acs_export_csv/?${queryParams}`,
    { filters, tableColumns },
    false
  );
}

export async function getSimpleTableData({ projectId, model }) {
  return await request.get(`/v2/table/?projectid=${projectId}&model=${model}`);
}

export async function getLeadFinderMetadata() {
  return await request.get('/v2/sqtable/?model=talent_finder_metadata');
}

export function updateRolesTable({ projectId, model, data, errorMessage }) {
  return request.patch(
    `/v2/table/roles/?projectid=${projectId}&model=${model}`,
    {
      data,
    },
    errorMessage
  );
}

export function applyRoleMapping({ projectId, model, payload }) {
  return request.post(
    `/v2/table/role_mapping/?projectid=${projectId}&model=${model}`,
    payload
  );
}

export async function getBenchmarkOrganizations(projectId) {
  return await request.get(
    `/v2/benchmarkorganizations/?blueprint=${projectId}`
  );
}

// Filters
// ============================================================================
export async function addFilterData(data) {
  return await request.post(`/v2/sreport/add_report/`, { data });
}

export async function deleteSavedFilter(id) {
  return await request.delete(`/v2/sreport/delete_report/?id=${id}`);
}

export async function getSavedFilterData() {
  return await request.get(`/v2/sreport/`);
}

export async function getSavedFilterBySearchId(searchId) {
  return await request.get(`/v2/sreport/${searchId}`);
}

export async function updateSavedFilterData(filterId, data) {
  return await request.patch(`/v2/sreport/update_report/?id=${filterId}`, {
    data,
  });
}

export async function updateSharedFilterData(filterId, data) {
  return await request.patch(`/v2/sreport/update_report/?id=${filterId}`, {
    data,
  });
}

// CSV Download
// ============================================================================
export async function getCsvData(projectId) {
  return await requestUrl(
    `/v2/table/export_csv/?model=aggregate_job_title&projectid=${projectId}`
  );
}

export async function exportProfiles(searchQuery, caseCode = null, key) {
  if (key) {
    return await request.get(
      `/v2/cohorts/export_s3_csv_file/?cache_key=${key}`
    );
  } else {
    return await request.post(
      caseCode
        ? `/v2/cohorts/export_s3_csv_file/?casecode=${caseCode}`
        : `/v2/cohorts/export_s3_csv_file/`,
      searchQuery
    );
  }
}

export async function getUser(projectId) {
  return await requestUrl(`/users/?blueprint=${projectId}`);
}

export async function postUser(projectId, data) {
  return await request.post(`/users/?blueprint=${projectId}`, data);
}

export async function patchUser(username, projectId, data) {
  return await request.patch(
    `/users/${username}/?blueprint=${projectId}`,
    data
  );
}

export async function deleteUser(username, projectId) {
  return await request.delete(`/users/${username}/?blueprint=${projectId}`);
}

export async function downloadFile(
  dataStream,
  fileName,
  onload,
  userHelpdeskMail = 'support@auraintel.com'
) {
  //@ts-ignore
  dataStream
    .blob()
    .then(blob =>
      blob.text().then(text => {
        if (text.includes('errorMessage')) {
          const error = JSON.parse(text);
          const errorMessage =
            error.errorMessage === 'request limit exceeded' ? (
              <p>
                Sorry, you have reached the monthly download limit of 50000
                rows.
                <br /> Please reach out to{' '}
                <a href={`mailto:` + userHelpdeskMail}>
                  {userHelpdeskMail}
                </a>{' '}
                for upgrading your account.
              </p>
            ) : (
              error.errorMessage
            );
          throw errorMessage;
        } else {
          return blob;
        }
      })
    )
    .then(blob => {
      const name = fileName;
      const a = window.document.createElement('a');
      a.download = name;
      document.body.appendChild(a);
      let reader = new FileReader();
      reader.readAsDataURL(blob); // converts the blob to base64 and calls onload
      reader.onload = function() {
        //@ts-ignore
        a.href = reader.result; // data url
        a.click();
        document.body.removeChild(a);
        setTimeout(() => {
          //@ts-ignore For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(reader.result);
        }, 100);
        if (onload) {
          onload();
        }
      };
    })
    .catch(error => showErrorToast(error, 'export-error'));
}

// Profiles Preview JSON
// ============================================================================
export async function getProfiles({ page, page_size, query }) {
  return await request.post(
    `/v2/cohorts/preview/?page=${page}&page_size=${page_size}`,
    query
  );
}

// Autocomplete API
// ============================================================================
export async function getAutocomplete({
  field,
  value,
  limit,
  onlyNames = true,
}) {
  return await request.get(
    `/v2/docmetadata/employees/?field=${field}&value=${value}&limit=${limit}&onlynames=${onlyNames}`
  );
}

export async function getOmniEmbedMetadata(params) {
  return await requestCoreApi.post(`/graphql`, {
    query: getOmniEmbedMetadataQuery,
    variables: params,
  });
}

// Autocomplete V2 API
// ============================================================================
export async function getAutocompleteV2({
  value,
  limit,
  industry,
  currentCountProfile = { min: 1 },
  page,
  withFilters,
  order,
  sortBy,
}) {
  let query;
  if (withFilters) {
    query = searchCompaniesQuery(availableFilterTerms);
  } else {
    query = searchCompaniesQuery();
  }

  const variables = {
    name: value,
    limit,
    page,
    industries: industry ? [industry] : undefined,
    currentCountProfile,
  };

  if (sortBy) {
    variables.order = order;
    variables.sortBy = sortBy;
  }

  return await requestCoreApi.post(`/graphql`, {
    query,
    variables,
  });
}

export async function getAutocompleteV2Deprecated({
  value,
  limit,
  industry,
  currentCountProfile = { min: 1 },
  order = 'DESC',
}) {
  return await requestCoreApi.post(`/graphql`, {
    query: searchCompaniesQueryV2Deprecated,
    variables: {
      name: value,
      limit,
      industries: industry ? [industry] : undefined,
      currentCountProfile,
      order,
    },
  });
}

// Roles V2
// ============================================================================
export async function getRolesUploadUrl(projectId, userId) {
  return await requestCoreApi.post(`/v1/roles/upload-url`, {
    projectId,
    userId,
  });
}

export async function submitRolesConfiguration(projectId, userId) {
  return await requestCoreApi.post(`/v1/roles/configuration`, {
    projectId,
    userId,
  });
}

// Organisation Users API
// ============================================================================
export async function getUserRole(userId) {
  return await request.get(`/v2/orgusers/${userId}/`);
}

export async function getMaintenanceStatus(username) {
  return await request.get(
    `/v2/orgusers/maintenance_status/?username=${username}`
  );
}

export async function getOrgUsers(page) {
  return await request.get(`/v2/orgusers/?page=${page}`);
}

export async function addOrgUser({ email, role }) {
  return await request.post(`/v2/orgusers/`, { email_address: email, role });
}

export async function editOrgUser({ userId, role }) {
  return await request.patch(`/v2/orgusers/${userId}/`, { role });
}

export async function deleteOrgUser(userId) {
  return await request.delete(`/v2/orgusers/${userId}/`);
}

export async function searchOrgUser(userEmail) {
  return await request.get(`/v2/orgusers/?search=${userEmail}&page=1`);
}

export async function getUserSubscriptions() {
  return await request.get(`/v2/orgusers/subscription/`);
}

export async function getActiveContracts() {
  return await request.get(`/v2/orgusers/contracts/`);
}

// Cube dashboards
// ============================================================================
export async function getCubeToken(projectId, queryParams = null) {
  const requestEP = `/v2/stories/get_cube_token/?project=${projectId}${
    queryParams ? '&params=' + queryParams : ''
  }`;
  return await request.get(requestEP);
}

// Lead Finder - Save searches
// ============================================================================
export async function getLFSavedSearches() {
  return await request.get('/v2/leadfinderfilters/');
}

export async function saveLFSearch(name, filter) {
  return await request.post('/v2/leadfinderfilters/', { name, filter });
}

export async function updateLFSearch(id, name, filter) {
  return await request.patch(`/v2/leadfinderfilters/${id}`, { name, filter });
}

// Databox
// ============================================================================
export async function getDataboxRegions(value) {
  return await request.get(
    `/v2/docmetadata/employees/?field=region&value=${value}&limit=20&onlynames=true`
  );
}

export async function getDataboxMetadata() {
  return await request.get('/v2/sqtable/?model=databox_metadata');
}

export async function getDataboxProfilesMatched(query) {
  return await request.post(`/v2/databox/profiles/`, query);
}

export async function exportDataboxProfiles(query, caseCode = null, key) {
  if (key) {
    return await request.get(
      `/v2/databox/export_s3_csv_file/?cache_key=${key}`
    );
  } else {
    return await request.post(
      caseCode
        ? `/v2/databox/export_s3_csv_file/?casecode=${caseCode}`
        : `/v2/databox/export_s3_csv_file/`,
      query
    );
  }
}

// Job Postings
// ============================================================================
export async function getJPCompanies(value) {
  return await request.get(
    `/v2/jobpostings/company_name/?value=${encodeURIComponent(value)}`
  );
}

export async function getShareProjectInfoStatus(email) {
  return await request.get(
    `/benchmarkorganizations/fetch_email/?email=${encodeURIComponent(email)}`
  );
}

export async function saveShareProjectInfoStatus(email) {
  return await request.post(`/benchmarkorganizations/add_email/`, {
    email: email,
  });
}

export async function getUpdateInfoStatus(email) {
  return await request.get(
    `/v2/jobpostings/seen_by_user/?email=${encodeURIComponent(email)}`
  );
}

export async function saveUpdateInfoStatus(email, data) {
  return await request.post(`/v2/jobpostings/add_user_seen/`, {
    email: email,
    data: data,
  });
}
